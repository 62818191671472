module.exports = {
    /** -- ERROR -- */
    // COMMON_ERROR:"COMMON_ERROR",
    /** -- AUTH -- */
    LOGIN_SUCCESS:"LOGIN_SUCCESS",
    LOGIN_FAILED:"LOGIN_FAILED",
    LOGIN_COMMON_ERROR:"LOGIN_COMMON_ERROR",
    LOGOUT:"LOGOUT",
    REGISTER_SUCCESS:"REGISTER_SUCCESS",
    REGISTER_FAILED:"REGISTER_FAILED",
    RESET_PASSWORD_SUCCESS:"RESET_PASSWORD_SUCCESS",
    RESET_PASSWORD_FAILED:"RESET_PASSWORD_FAILED",
    REQUEST_OTP_SUCCESS:"REQUEST_OTP_SUCCESS",
    REQUEST_OTP_FAILED:"REQUEST_OTP_FAILED",
    VERIFY_OTP_SUCCESS:"VERIFY_OTP_SUCCESS",
    VERIFY_OTP_FAILED:"VERIFY_OTP_FAILED",
    VERIFY_EMAIL:"VERIFY_EMAIL",
    FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
    FORGOT_PASSWORD_FAILED: "FORGOT_PASSWORD_FAILED",
    VERIFICATION_REQUEST_SUCCESS: "VERIFICATION_REQUEST_SUCCESS",
    VERIFICATION_REQUEST_FAILED: "VERIFICATION_REQUEST_FAILED",
    GET_PERMISSIONS_SUCCESS: "GET_PERMISSIONS_SUCCESS",
    GET_PERMISSIONS_FAILED: "GET_PERMISSIONS_FAILED",
    USER_WORKSPACE_LIST_SUCCESS: "USER_WORKSPACE_LIST_SUCCESS",
    USER_WORKSPACE_LIST_FAILED: "USER_WORKSPACE_LIST_FAILED",
    PROFILE_SUCCESS:"PROFILE_SUCCESS",
    /** -- DASHBOARD -- */
    GET_DASHBOARD_STATS_SUCCESS:"GET_DASHBOARD_STATS_SUCCESS",
    GET_DASHBOARD_STATS_FAILED:"GET_DASHBOARD_STATS_FAILED",
    DASHBOARD_COMMON_ERROR:"DASHBOARD_COMMON_ERROR",

    /** -- USER -- */
    CREATE_USER_SUCCESS:"CREATE_USER_SUCCESS",
    CREATE_USER_FAILED:"CREATE_USER_FAILED",    
    LIST_USER_SUCCESS:"LIST_USER_SUCCESS",
    LIST_USER_FAILED:"LIST_USER_FAILED",
    PUT_USER_SUCCESS:"PUT_USER_SUCCESS",
    PUT_USER_FAILED:"PUT_USER_FAILED",
    DELETE_USER_SUCCESS:"DELETE_USER_SUCCESS",
    DELETE_USER_FAILED:"DELETE_USER_FAILED", 
    GET_ONE_USER_SUCCESS:"GET_ONE_USER_SUCCESS",
    GET_ONE_USER_FAILED:"GET_ONE_USER_FAILED",
    USER_COMMON_ERROR:"USER_COMMON_ERROR",

    // PROJECTS

    CREATE_PROJECT_SUCCESS: "CREATE_PROJECT_SUCCESS",
    CREATE_PROJECT_FAILED: "CREATE_PROJECT_FAILED",
    LIST_PROJECT_SUCCESS: "LIST_PROJECT_SUCCESS",
    LIST_PROJECT_FAILED: "LIST_PROJECT_FAILED",
    PUT_PROJECT_SUCCESS:"PUT_PROJECT_SUCCESS",
    PUT_PROJECT_FAILED: "PUT_PROJECT_FAILED",
    DELETE_PROJECT_SUCCESS: "DELETE_PROJECT_SUCCESS",
    DELETE_PROJECT_FAILED: "DELETE_PROJECT_FAILED",
    GET_SINGLE_PROJECT_SUCCESS: "GET_SINGLE_PROJECT_SUCCESS",
    GET_SINGLE_PROJECT_FAILED: "GET_SINGLE_PROJECT_FAILED",
    PROJECT_COMMON_ERROR: "PROJECT_COMMON_ERROR",
    
    // TASKS

    CREATE_TASK_SUCCESS: "CREATE_TASK_SUCCESS",
    CREATE_TASK_FAILED: "CREATE_TASK_FAILED",
    LIST_TASK_SUCCESS: "LIST_TASK_SUCCESS",
    LIST_TASK_FAILED: "LIST_TASK_FAILED",
    PUT_TASK_SUCCESS:"PUT_TASK_SUCCESS",
    PUT_TASK_FAILED: "PUT_TASK_FAILED",
    DELETE_TASK_SUCCESS: "DELETE_TASK_SUCCESS",
    DELETE_TASK_FAILED: "DELETE_TASK_FAILED",
    GET_SINGLE_TASK_SUCCESS: "GET_SINGLE_TASK_SUCCESS",
    GET_SINGLE_TASK_FAILED: "GET_SINGLE_TASK_FAILED",
    TASK_COMMON_ERROR: "TASK_COMMON_ERROR",
    TASK_REORDER: "TASK_REORDER",
    TASK_REORDER_ERROR: "TASK_REORDER_ERROR",

    // Members
    CREATE_MEMBER_SUCCESS: "CREATE_MEMBER_SUCCESS",
    CREATE_MEMBER_FAILED: "CREATE_MEMBER_FAILED",
    LIST_MEMBER_SUCCESS: "LIST_MEMBER_SUCCESS",
    LIST_MEMBER_FAILED: "LIST_MEMBER_FAILED",
    PUT_MEMBER_SUCCESS: "PUT_MEMBER_SUCCESS",
    PUT_MEMBER_FAILED: "PUT_MEMBER_FAILED",
    DELETE_MEMBER_SUCCESS: "DELETE_MEMBER_SUCCESS",
    DELETE_MEMBER_FAILED: "DELETE_MEMBER_SUCCESS",
    GET_SINGLE_MEMBER_SUCCESS: "GET_SINGLE_MEMBER_SUCCESS",
    GET_SINGLE_MEMBER_FAILED: "GET_SINGLE_MEMBER_FAILED",
    MEMBER_COMMON_ERROR: "MEMBER_COMMON_ERROR",
    INVITE_ACCEPT_SUCCESS: "INVITE_ACCEPT_SUCCESS",
    INVITE_ACCEPT_FAILED: "INVITE_ACCEPT_FAILED",
    INVITE_LIST_SUCCESS: "INVITE_LIST_SUCCESS",
    INVITE_LIST_FAILED: "INVITE_LIST_FAILED",
    INVITE_DELETE_SUCCESS: "INVITE_DELETE_SUCCESS",
    INVITE_DELETE_FAILED: "INVITE_DELETE_FAILED",
    RESEND_INVITE_SUCCESS: "RESEND_INVITE_SUCCESS",
    RESEND_INVITE_FAILED: "RESEND_INVITE_FAILED",

    // Client 

    CREATE_CLIENT_SUCCESS: "CREATE_CLIENT_SUCCESS",
    CREATE_CLIENT_FAILED: "CREATE_CLIENT_FAILED",
    LIST_CLIENT_SUCCESS: "LIST_CLIENT_SUCCESS",
    LIST_CLIENT_FAILED: "LIST_CLIENT_FAILED",
    PUT_CLIENT_SUCCESS: "PUT_CLIENT_SUCCESS",
    PUT_CLIENT_FAILED: "PUT_CLIENT_FAILED",
    DELETE_CLIENT_SUCCESS: "DELETE_CLIENT_SUCCESS",
    DELETE_CLIENT_FAILED: "DELETE_CLIENT_FAILED",
    GET_SINGLE_CLIENT_SUCCESS: 'GET_SINGLE_CLIENT_SUCCESS',
    GET_SINGLE_CLIENT_FAILED: "GET_SINGLE_CLIENT_FAILED", 
    CLIENT_COMMON_ERROR: "CLIENT_COMMON_ERROR",

    TIME_TRACKING_STATUS: "TIME_TRACKING_STATUS",
    TRACKING_COMMON_ERROR: "TRACKING_COMMON_ERROR",

    // Workspace (Company)

    WORKSPACE_CREATE_SUCCESS: "WORKSPACE_CREATE_SUCCESS",
    WORKSPACE_UPDATE_SUCCESS: "WORKSPACE_UPDATE_SUCCESS",
    WORKSPACE_CREATE_FAILED: "WORKSPACE_CREATE_FAILED",
    WORKSPACE_COMMON_ERROR: "WORKSPACE_COMMON_ERROR",
    GET_WORKSPACE_ROLE_SUCCESS: "GET_WORKSPACE_ROLE_SUCCESS",
    LEAVE_WORKSPACE_SUCCESS: "LEAVE_WORKSPACE_SUCCESS",
    LEAVE_WORKSPACE_FAILED: "LEAVE_WORKSPACE_FAILED",
    UPDATE_OWNERSHIP_SUCCESS: "UPDATE_OWNERSHIP_SUCCESS",
    UPDATE_OWNERSHIP_FAILED: "UPDATE_OWNERSHIP_FAILED",
    LIST_WORKSPACE_SUCCESS: "LIST_WORKSPACE_SUCCESS",
    WORKSPACE_DELETE_SUCCESS: "WORKSPACE_DELETE_SUCCESS",
    REFRESH_DASHBOARDS: "REFRESH_DASHBOARDS",

    CLEAR_MESSAGES: "CLEAR_MESSAGES",
    SHOW_LOADER: 'SHOW_LOADER',
    HIDE_LOADER: 'HIDE_LOADER',

    WORKFLOW_GET_SUCCESS: "WORKFLOW_GET_SUCCESS",
    WORKFLOW_ERROR: "WORKFLOW_ERROR",
    WORKFLOW_CREATE_SUCCESS: "WORKFLOW_CREATE_SUCCESS",
    WORKFLOW_UPDATE_SUCESS: "WORKFLOW_UPDATE_SUCESS",
    WORKFLOW_SUCCESS: "WORKFLOW_SUCCESS",
    WORKFLOW_DELETE_SUCCESS: "WORKFLOW_DELETE_SUCCESS",

    LIVE_ACTIVITY_LIST_SUCCESS: "LIVE_ACTIVITY_LIST_SUCCESS",
    ACTIVITY_COMMON_ERROR: "ACTIVITY_COMMON_ERROR",
    RECORDED_ACTIVITY_SUCCESS: "RECORDED_ACTIVITY_SUCCESS",

    CURRENT_PROJECT: "PROJECT_STATES",
    SELECTED_MEMBERS: "SELECTED_MEMBERS",
    SELECTED_STATUS: "SELECTED_STATUS",
    SELECTED_WORKFLOW: "SELECTED_WORKFLOW",
    SELECTED_FILES: "SELECTED_FILES",
    CURRENT_TASK: "CURRENT_TASK",
    SHOW_STATUS_MODAL: "SHOW_STATUS_MODAL",
    SHOW_MEMBERS_MODAL: "SHOW_MEMBERS_MODAL",
    SHOW_WORKFLOW_MODAL: "SHOW_WORKFLOW_MODAL",
    SHOW_FILES_MODAL: "SHOW_FILES_MODAL",
    SHOW_TASK_MODAL: "SHOW_TASK_MODAL",
    ALL_MEMBERS: "ALL_MEMBERS",
    PROJECT_FORM: "PROJECT_FORM",
    TASK_FORM: "TASK_FORM",
    ACTIVE_FORM_TYPE: "ACTIVE_FORM_TYPE",
    RESET_FORMS: "RESET_FORMS",
    SHOW_PREVIEW_MODAL: "SHOW_PREVIEW_MODAL",
    ALL_CLIENTS: "ALL_CLIENTS",
    ASSIGN_MEMBER: "ASSIGN_MEMBER",
    EDIT_PROJECT_FORM: "EDIT_PROJECT_FORM",
    DIRECT_UPDATE: "DIRECT_UPDATE",
    TASK_FORM_TYPE: "TASK_FORM_TYPE",

    CREATE_LIST_COMMENT:"CREATE_LIST_COMMENT",
    CREATE_POST_LIST_COMMENT:"CREATE_POST_LIST_COMMENT",
    DELETE_COMMENT: "DELETE_COMMENT",
}
